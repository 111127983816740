const config = {
    // customisation
    env: 'h2l',
    color: '#09549d',
    rgbPDF: {
        r: 0.035,
        g: 0.329,
        b: 0.616,
    },
    header: {
        backgroundPosition: '50% 65%',
        backgroundSize: 'cover',
        logoContainerPadding: 15,
        logo: {
            height: '100',
            width: '100',
            backgroundColor: 'white',
            href: 'https://h2l.fr',
        },
        title: '',
    },
    name: 'H2L',
    custom: {
        // ajouter une clé pour ajouter une ligne
        1: '4 rue Charles Boule',
        2: '19360 Malemort-sur-Corrèze',
    },
    mail: 'contact@h2l.fr',
    privacy: 'https://h2l.fr/rgpd/',
    legal: 'https://h2l.fr/mentions-legales/',
    modalType: '', // 'achille' or ''
    hotline: '', // '09 00 00 00 00' or ''
    hotjar: {
        hjid: 0,
        hjsv: 0,
    },
    googleAnalyticId: '',

    apiGoogle: {
        key: 'AIzaSyCRMyPFYFvqYqWSGAcoWBDNSSzy7ZliNxU',
        libraries: ['geometry', 'places'],
    },
    // internal urls
    sendFormUrl: 'https://simulateur-photovoltaique.h2l.fr/sendPDF',
    fetchBill: 'https://simulateur-photovoltaique.h2l.fr/estimated-invoice',
    fetchEstimation: 'https://simulateur-photovoltaique.h2l.fr/pre-calculate',

    // external urls
    achilleUrl: '',
};

export default config;

